import React, { useEffect } from 'react';

import { View, ScrollView, StyleSheet } from 'react-native';
import { Headings } from '@rugby-au/headings';
import { Container } from '@rugby-au/container';
import { MembershipList } from '@rugby-au/membership-card';
import { WebHeader } from '@rugby-au/web-header';
import { Banner } from '@rugby-au/banner';
import { useAppConfig } from '@rugby-au/app-config';
import { ISiteColors, ISpacing, ITheme, ITypography, useTheme } from '@rugby-au/theme';
import { getProductList, loadBasicAuthHeaders, setItem } from '@rugby-au/services';
import { MembershipProductProps } from '@rugby-au/membership';
import { FeatureCardList } from '@rugby-au/feature-card';
import { Text } from '@rugby-au/commons';
import { Image } from '@rugby-au/image';
import Link from 'next/link';
import { loginWithAuthCode } from '@rugby-au/services/src/rx/auth/user';

export default function App({ products, user }: { products: MembershipProductProps[]; user: any }) {
  // get header config
  const { header } = useAppConfig();
  const { colors, spacing, typography, headings } = useTheme();
  useEffect(() => {
    if (user) {
      setItem('user', user.userData);
    }
  }, [user]);

  const styles = getStyles({ colors, spacing, typography, headings });
  // const extraProducts = products.filter(product => product.category === 'extra');
  return (
    <ScrollView>
      <WebHeader {...header} />
      <Banner
        type={'image'}
        // text={'Wallabies First'}
        image={{
          url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/2637a458-83dd-4c27-c97c-08db872b653e.png',
          alt: 'Wallabies First Membership Banner',
          aspectRatio: 3 / 1,
        }}
      />
      <Container>
        <View style={{ marginVertical: spacing['2xlarge'] }} />
        {!products ||
          (products.length === 0 ? (
            <View style={styles.noItemsContainer}>
              <Headings title={'Sorry there are no memberships available at the moment.'} level={'2'} />
              <Text style={styles.textContainer}>
                Please subscribe to{' '}
                <a href={'https://team.rugby/'} target={'_blank'} style={styles.linkStyle}>
                  Team Rugby
                </a>{' '}
                to be notified when next seasons membership packages become available.
              </Text>
              <Text style={styles.textContainer}>
                Alternatively please send any enquiries through to{' '}
                <a href={'mailto:customer.service@rugby.com.au'} target={'_blank'} style={styles.linkStyle}>
                  customer.service@rugby.com.au.
                </a>
              </Text>
            </View>
          ) : (
            <MembershipList
              membershipList={products
                .filter(product => product.category === 'main')
                .map(product => ({
                  id: product._id,
                  appearance: (product.cardAppearance && product.cardAppearance !== '' ? product.cardAppearance : 'light') as ITheme,
                  title: product.title,
                  subTitle: product.subtitle,
                  ...(!product.mode || product.mode === 'Sale'
                    ? {
                        joinButton: {
                          title: 'Join Now',
                          appearance: (product.buttonAppearance && product.buttonAppearance !== '' ? product.buttonAppearance : 'primary') as ITheme,
                          href: `/checkout?productId=${product._id}`,
                        },
                        moreInfoButton: {
                          title: 'More Info',
                          appearance: (product.buttonAppearance && product.buttonAppearance !== '' ? product.buttonAppearance : 'primary') as ITheme,
                          href: `/membership/${product._id}`,
                        },
                      }
                    : product.mode === 'Link'
                    ? {
                        joinButton: {
                          title: 'Join Now',
                          appearance: (product.buttonAppearance && product.buttonAppearance !== '' ? product.buttonAppearance : 'primary') as ITheme,
                          href: product.externalLink,
                        },
                        moreInfoButton: {
                          title: 'More Info',
                          appearance: (product.buttonAppearance && product.buttonAppearance !== '' ? product.buttonAppearance : 'primary') as ITheme,
                          href: product.moreInfoLink ? product.moreInfoLink : `/membership/${product._id}`,
                        },
                      }
                    : product.mode === 'Display'
                    ? {
                        joinButton: {
                          title: 'Coming Soon',
                          appearance: (product.buttonAppearance && product.buttonAppearance !== '' ? product.buttonAppearance : 'primary') as ITheme,
                          disabled: true,
                        },
                      }
                    : {}),
                  summaryList: product.features,
                  image: {
                    alt: product.title,
                    url: product.image,
                  },
                  isGradient: product.isGradient,
                }))}
              numColumns={3}
            />
          ))}
        <View style={{ marginVertical: spacing['2xlarge'] }} />

        <View>
          <Text style={styles.headerBlub}>{'Become a member of the Wallabies family today where passion, pride, unforgettable moments and exclusive benefits unite.'}</Text>
        </View>
        <View style={{ marginVertical: spacing['2xlarge'] }} />

        <FeatureCardList
          list={[
            {
              id: '12',
              appearance: 'light',
              image: {
                url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/0e2eae3e-e9aa-4edf-c97a-08db872b653e.jpg',
                alt: 'Wallabies Membership Testimonials © Karen Watson',
              },
              isNative: false,
              quote: { text: 'We love being a member to access the best seats in the house before they are put of sale to the general public', author: 'Bruce Gilbert' },
            },
            {
              id: '12',
              appearance: 'light',
              image: {
                url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/940e4d93-92bf-47ef-c97b-08db872b653e.jpg',
                alt: 'Wallabies Membership Testimonials © Karen Watson',
              },
              isNative: false,
              quote: { text: 'I love access to the incredible events and meeting our favourite Wallabies players', author: 'Chris Horn' },
            },
            {
              id: '12',
              appearance: 'light',
              image: {
                url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/b2d4cda4-c9e1-482a-c979-08db872b653e.jpg',
                alt: 'Wallabies Membership Testimonials © Karen Watson',
              },

              isNative: false,
              quote: {
                text: "It's great having The Wallaby First Community around the Country that we can catch up with before, during and after the Tests",
                author: 'Neil Short',
              },
            },
          ]}
          numColumns={3}
        />
        <View style={{ marginVertical: spacing['2xlarge'] }} />

        <Link href={'https://team.rugby'} target="_blank">
          <View
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}>
            <Image
              style={{ resizeMode: 'contain', aspectRatio: 5 / 1, flex: 1 }}
              url={'https://d26phqdbpt0w91.cloudfront.net/NonVideo/e15a1458-46fb-45a4-c97d-08db872b653e.png'}
              alt={'Join Team Rugby - Wallabies Membership'}
            />
          </View>
        </Link>
        <View style={{ marginVertical: spacing['2xlarge'] }} />

        {/* {extraProducts && extraProducts.length > 0 && (
          <>
            <View style={{ flex: 1, marginVertical: 16 }}>
              <Headings title={'Help support the team'} level={'2'} />
            </View>
            <MembershipList
              membershipList={products
                .filter(product => product.category === 'extra')
                .map(product => ({
                  id: product._id,
                  appearance: 'light',
                  title: product.title,
                  subTitle: product.subtitle,
                  joinButton: {
                    title: 'Join Now',
                    appearance: 'primary',
                  },
                  summaryList: product.features,
                  moreInfoButton: {
                    title: 'More Info',
                    appearance: 'primary',
                  },
                  image: {
                    alt: product.title,
                    url: product.image,
                  },
                }))}
              numColumns={3}
            />
          </>
        )} */}

        <View style={{ marginTop: 20 }} />
      </Container>
    </ScrollView>
  );
}

export async function getServerSideProps(context: any) {
  const { authCode, clientId } = context.query;
  console.log('🚀 ~ file: index.tsx:217 ~ getServerSideProps ~ authCode, clientId:', authCode, clientId);

  let user = null;
  if (authCode && clientId) {
    const headers = loadBasicAuthHeaders(`${process.env.NEXT_PUBLIC_API_USERNAME}:${process.env.RX_API_SERVER_TOKEN}`);
    console.log('index.tsx line 228 - headers ', headers);
    user = await loginWithAuthCode({ authCode, clientId, headers });
    console.log('🚀 ~ file: index.tsx:222 ~ getServerSideProps ~ user:', user);
  }
  const products = await getProductList({ entityType: 'national', entity: 'RAU', type: 'Membership' });
  //console.log("🚀 ~ file: index.tsx:216 ~ getStaticProps ~ products:", products)
  return { props: { products: products ?? [], user } };
}

// export async function getStaticProps() {
//   const products = await getProductList({ entityType: 'national', entity: 'RAU', type: 'membership' });
//   //console.log("🚀 ~ file: index.tsx:216 ~ getStaticProps ~ products:", products)
//   return { props: { products: products ?? [] }, revalidate: 600 };
// }

const getStyles = ({ colors, spacing, typography, headings }: { colors: ISiteColors; spacing: ISpacing; typography: ITypography; headings: any }) =>
  StyleSheet.create({
    noItemsContainer: { paddingVertical: spacing['2xlarge'] },
    textContainer: { paddingVertical: spacing.large, fontSize: typography.fontSize.large },
    linkStyle: { textDecorationLine: 'underline', color: colors.primary },
    headerBlub: { ...headings[1], fontSize: typography.fontSize['4xlarge'], textAlign: 'center', lineHeight: '1.5' },
  });
